// extracted by mini-css-extract-plugin
export var ServicesWhyAngular = "WhyHireAngular-module--ServicesWhyAngular--4554e";
export var card = "WhyHireAngular-module--card--018ea";
export var cardWrapper = "WhyHireAngular-module--cardWrapper--b930b";
export var description = "WhyHireAngular-module--description--31419";
export var heading = "WhyHireAngular-module--heading--ca2ff";
export var loader = "WhyHireAngular-module--loader--99225";
export var lodaMoreBtn = "WhyHireAngular-module--lodaMoreBtn--6ee58";
export var lodalessBtn = "WhyHireAngular-module--lodalessBtn--54978";
export var showlessdiv = "WhyHireAngular-module--showlessdiv--9ec6d";
export var spin = "WhyHireAngular-module--spin--7bc6d";
export var trailBg = "WhyHireAngular-module--trailBg--5669b";