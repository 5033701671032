// extracted by mini-css-extract-plugin
export var angularMaximizeBg = "Maximize-module--angularMaximizeBg--a71b0";
export var backendEnsureHead = "Maximize-module--backendEnsureHead--d083f";
export var bgColor = "Maximize-module--bgColor--26c13";
export var bottomExperience = "Maximize-module--bottomExperience--508a1";
export var btn_white2_border_banner_web_App_maximize_btn = "Maximize-module--btn_white2_border_banner_web_App_maximize_btn--5bb07";
export var drupalMaximizeBg = "Maximize-module--drupalMaximizeBg--5aabf";
export var experienceData = "Maximize-module--experienceData--1831f";
export var experienceDetails = "Maximize-module--experienceDetails--9fb0c";
export var experienceTitle = "Maximize-module--experienceTitle--2ca76";
export var headingProduct = "Maximize-module--headingProduct--8482e";
export var headingWeb = "Maximize-module--headingWeb--9ef83";
export var maximizeAngularDevelopers = "Maximize-module--maximizeAngularDevelopers--da5a0";
export var maximizeBtnWeb = "Maximize-module--maximizeBtnWeb--f5e7e";
export var maximizeContent = "Maximize-module--maximizeContent--b86ae";